// src/pages/Home.js
import React from 'react';
import './Home.css';
import './Tools.css'; // We can reuse the existing Tools.css for styling

const Home = () => {
  return (
    <div className="home-container">
      {/* Intro Section */}
      <div className="intro-section">
        <h1>Crush the Competition with <span>Solana Snipers</span></h1>
        <p>
          Unleash the full potential of your Solana projects with Solana Snipers. We offer a comprehensive suite of automation tools tailored to streamline development, boost visibility, and provide real-time insights within the Solana ecosystem. From efficient smart contract deployment to advanced analytics, Solana Snipers empowers developers and project managers to optimize their workflows and achieve unparalleled results on the Solana blockchain.
        </p>
      </div>

      {/* Tools Section */}
      <div className="tools-container">
        <div className="tool-card">
          <h2>Telegram Sniper</h2>
          <ul>
            <li>Be the first to snipe Telegram Channel Calls Every Time</li>
            <li>Target multiple Public/Private Telegram channels simultaneously</li>
            <li>Customizable filters to snipe only the best tokens</li>
          </ul>
          <p>
            Gain a competitive edge in the crypto market with lightning-fast token detection and automated trading capabilities.
          </p>
          <button className="learn-more">Learn More</button>
          <span className="price-tag">PRICE: 2 SOL (Monthly Access)</span>
          <span className="price-tag">PRICE: 20 SOL (Lifetime)</span>
          
        </div>

        <div className="tool-card">
          <h2>DanBump Volume Bot</h2>
          <p>
            Dantheman's custom Bumpbot / Volume Bot. 
          </p>
          <a href="https://t.me/DamBumpBot" target="_blank" rel="noopener noreferrer">
            <button className="learn-more">Start Bumping</button>
          </a>
          <span className="price-tag">PRICE: FREE</span>
        </div>
        
        <div className="tool-card">
          <h2>PumpFun Bundler</h2>
          <p>
            Placeholder Text for Solana Snipers. 
          </p>
          <button className="learn-more">Learn More</button>
          <span className="price-tag">PRICE: 2 SOL</span>
          <span className="price-tag">PRICE: 20 SOL (Lifetime)</span>
        </div>
        
        <div className="tool-card">
          <h2>Volume Bot</h2>
          <p>
            Placeholder Text for Solana Snipers. 
          </p>
          <button className="learn-more">Learn More</button>
          <span className="price-tag">PRICE: 2 SOL</span>
          <span className="price-tag">PRICE: 20 SOL (Lifetime)</span>
        </div>        
      </div>
    </div>
  );
};

export default Home;
